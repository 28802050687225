// Cry baby, cry

$(document).ready(function() {
  // Loader
  $('.Loader').addClass('Loader--hide');

  // Btn
  $('.btn').on('click', function() {
    moveTo('.main', 2);
  });

  // Sliders init
  $('.Slider-sync').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    swipe: false,
    asNavFor: '.Slider-nav',
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          swipe: true
        }
      }
    ]
  });

  $('.Slider-nav').slick({
    slidesToShow: 9,
    slidesToScroll: 9,
    asNavFor: '.Slider-sync',
    dots: false,
    arrows: true,
    vertical: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          centerMode: true,
          vertical: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          vertical: false
        }
      }
    ]
  });

});
